import { getSelectedLocale } from '@/utils/locale.utils'
import ContentfulWrapper from '@telia/contentful-wrapper'
import { getHost } from '@/utils/domain.utils'
import { createInstance } from '@/services/axios.api'
import { isLoggedIn } from '@/utils/user.utils'
export const api = createInstance(process.env.VUE_APP_USER_ADMIN_SERVICE)

const client = new ContentfulWrapper({
  space: process.env.VUE_APP_CONTENTFUL_SPACE,
  accessToken: '_',
  host: getHost(),
  basePath: process.env.VUE_APP_CONTENTFUL_WRAPPER_BASE_PATH,
  assetsHost: getHost() + '/.api/infra/contentful-gateway',
  environment: process.env.VUE_APP_CONTENTFUL_WRAPPER_ENVIRONMENT
})

const getCategories = async () => {
  return client.getEntries({
    'include': 3,
    'content_type': 'twsContactUsCategory',
    'fields.authenticated': isLoggedIn(),
    'order': 'fields.order',
    'locale': getSelectedLocale()
  })
}

const fetchForm = async url => {
  return await api.get(`${'/' + getSelectedLocale()}` + url)
}

export default {
  getCategories,
  fetchForm
}
