<template>
  <div
    ref="headerContainer"
    class="header-container">
    <div
      :class="{ 'navigation-container--borderless': megaMenuActive || !isLoggedIn() }"
      class="container navigation-container">
      <TwsLanguageToggle />
      <nav class="navigation">
        <LocalisedLink
          class="navigation__logo"
          aria-label="Startsida"
          to="/">
          <tws-telia-wholesale-logo
            class="logo logo--desktop"
            width="228"
            height="44" />

          <tws-telia-logo
            class="logo logo--mobile"
            height="44"
            width="115" />
        </LocalisedLink>

        <tws-desktop-menu
          v-if="!isContentfulLoading"
          @toggleMegaMenu="toggleMegaMenu" />

        <tws-login-menu v-if="isLoggedIn()" />

        <div class="header-actions">
          <tws-login-menu-login-button v-if="!isLoggedIn()" />

          <tws-header-action
            class="header-action--search"
            @click="openSearchField">
            <template #icon>
              <TeliaCoreIcon
                name="search_thin"
                size="2.4rem" />
            </template>

            <template #text>
              {{ $t('header.search') }}
            </template>
          </tws-header-action>

          <tws-header-action
            class="header-action--mobile header-action--menu"
            @click="toggleMobileMenu">
            <template #icon>
              <tws-mobile-menu-icon
                height="24"
                width="24" />
            </template>

            <template #text>
              {{ $t('header.menu') }}
            </template>
          </tws-header-action>

          <div
            v-show="searchFieldVisible"
            class="header-search">
            <input
              ref="searchInput"
              v-model="searchFragment"
              class="header-search__input"
              :placeholder="$t('header.search-placeholder')"
              @keyup.enter="handleSearch">
            <tws-borderless-button
              class="header-search__cancel"
              @click="closeSearchField">
              {{ $t('common.cancel') }}
            </tws-borderless-button>
          </div>
        </div>
      </nav>
    </div>

    <template v-if="!isContentfulLoading">
      <tws-mobile-menu
        v-show="mobileMenuVisible"
        :items="headerItems"
        :mega-menus="megaMenus"
        @close="toggleMobileMenu" />

      <template v-for="(menu, index) in megaMenus">
        <tws-mega-menu
          v-show="menu.visible"
          :key="index"
          :ref="menu.name"
          :categories="menu.categories" />
      </template>
    </template>

    <tws-secured-header
      v-if="isLoggedIn()"
      :is-public-mega-menu-open="megaMenuActive"
      @onMegaMenuExpand="collapseMegaMenu" />
  </div>
</template>

<script>

import {
  mapActions,
  mapState
} from 'vuex'

import MegaMenu from '@/components/common/mega-menu/mega-menu'
import SecuredHeader from '@/components/header/secured-header/secured-header'
import TwsTeliaWholesaleLogo from '@/img/svg/telia-wholesale-logo.svg'
import TwsTeliaLogo from '@/img/svg/telia-logo.svg'
import TwsMobileMenuIcon from '@/img/svg/mobile-menu-icon.svg'

import TwsLanguageToggle from '@/components/header/language-toggle/language-toggle'

import HeaderAction from '@/components/header/header-action/header-action'
import DesktopMenu from '@/components/header/desktop-menu/desktop-menu'
import MobileMenu from '@/components/header/mobile-menu/mobile-menu'
import LoginMenu from '@/components/header/login-menu/login-menu'
import LoginMenuLoginButton from '@/components/header/login-menu/login-menu-login-button/login-menu-login-button'

import { TwsBorderlessButton } from 'tws-vue-components'
import { isLoggedIn } from '@/utils/user.utils'

export default {
  components: {
    'tws-mega-menu': MegaMenu,
    'tws-secured-header': SecuredHeader,
    'tws-header-action': HeaderAction,
    'tws-desktop-menu': DesktopMenu,
    'tws-mobile-menu': MobileMenu,
    'tws-login-menu': LoginMenu,
    'tws-login-menu-login-button': LoginMenuLoginButton,
    TwsBorderlessButton,
    TwsLanguageToggle,
    TwsTeliaWholesaleLogo,
    TwsTeliaLogo,
    TwsMobileMenuIcon
  },
  props: {
    siteSearchUrl: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      megaMenuActive: false,
      mobileMenuVisible: false,
      searchFieldVisible: false,
      searchFragment: ''
    }
  },
  computed: {
    ...mapState('publicHeader', [
      'headerItems',
      'megaMenus',
      'isContentfulLoading'
    ])
  },
  async created () {
    document.addEventListener('click', this.closeMegaMenuEvent)

    await this.fetchPublicHeaderItems()
    this.headerItems.sort((a, b) => a.order - b.order)

    if (isLoggedIn()) {
      this.fetchSecureHeaderData()
    }
  },
  destroyed () {
    document.removeEventListener('click', this.closeMegaMenuEvent)
  },
  methods: {
    isLoggedIn,
    ...mapActions('secureHeader', [
      'fetchOrders',
      'fetchMyOrders',
      'fetchTickets'
    ]),
    ...mapActions('publicHeader', [
      'fetchPublicHeaderItems',
      'updateMegaMenus'
    ]),
    closeMegaMenuEvent (e) {
      if (this.megaMenuActive) {
        const isClickedWithinHeader = this.$refs.headerContainer.contains(e.target)

        if (!isClickedWithinHeader) {
          this.collapseMegaMenu()
        }

        if (isClickedWithinHeader && e.target.nodeName === 'A') {
          this.collapseMegaMenu()
        }
      }
    },
    fetchSecureHeaderData () {
      this.fetchOrders()
      this.fetchMyOrders()
      this.fetchTickets()
    },
    toggleMegaMenu (name) {
      this.toggleMegaMenuItem(name)
      this.isMegaMenuActive()
    },
    isMegaMenuActive () {
      this.megaMenuActive = false

      Object.keys(this.megaMenus).forEach(key => {
        if (this.megaMenus[key].visible) {
          this.megaMenuActive = true
        }
      })
    },
    toggleMegaMenuItem (name) {
      this.updateMegaMenus(Object.entries(this.megaMenus).reduce((acc, [
        key,
        value
      ]) => {
        acc[key] = {
          ...value,
          visible: key === name ? !this.megaMenus[key].visible : false
        }

        return acc
      }, {}))
    },
    collapseMegaMenu () {
      this.updateMegaMenus(Object.entries(this.megaMenus).reduce((acc, [
        key,
        value
      ]) => {
        acc[key] = {
          ...value,
          visible: false
        }

        return acc
      }, {}))

      this.megaMenuActive = false
    },
    toggleMobileMenu () {
      this.mobileMenuVisible = !this.mobileMenuVisible

      document.body.style.overflow = this.mobileMenuVisible ? 'hidden' : 'auto'
    },
    openSearchField () {
      this.searchFieldVisible = true

      // necessary timeout before field become focusible on
      this.$nextTick(() => {
        this.$refs['searchInput'].focus()
      })
    },
    closeSearchField () {
      this.searchFieldVisible = false
    },
    handleSearch () {
      this.$router.push({
        name: 'search-result',
        query: {
          query: this.searchFragment
        },
        params: this.$route.params
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.header-container {
  box-shadow: 0 2px 4px rgba(0, 0, 0, .16);
  position: relative;
}

.navigation-container {
  border-bottom: solid 1px $medium-grey;

  &--borderless {
    border-bottom: 0;
  }
}

.navigation {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &__logo {
    margin: 22px 88px 22px 0;
  }
}

.logo path {
  fill: $core-purple;
}

.header-actions {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.header-action {
  &:not(:first-child) {
    margin-left: 20px;
  }
}

.header-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  right: 0;
  position: absolute;
  height: 100%;
  width: 400px;

  &__input {
    background-color: $light-grey;
    height: 48px;
    width: 400px;
    border-radius: 24px;
    border-width: 0;
    font-size: 22px;
    padding-left: 30px;

    &:focus {
      outline: none;
    }
  }

  &__cancel {
    margin-left: 10px;
  }
}

@media (min-width: $breakpoint-md) {
  .header-action--mobile {
    display: none;
  }
}

@media (max-width: $breakpoint-md) {
  .desktop-menu,
  .login-menu,
  .header-action--login {
    display: none;
  }

  .navigation__logo {
    margin-right: 0;
  }
}

@media (min-width: 341px) {
  .logo--mobile {
    display: none;
  }
}

@media (max-width: 340px) {
  .logo--desktop {
    display: none;
  }
}
</style>
