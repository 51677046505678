import DateTimeUtils from '@/common/datetime-utils'

import OperationalNewsService from '@/services/operational-news.service'
import { isLoggedIn } from '@/utils/user.utils'

const STORAGE_KEY = 'closed_news'

const wrapNews = news => {
  const storageItems = window.localStorage.getItem(STORAGE_KEY)
  const closedItems = storageItems === null ? [] : JSON.parse(storageItems)

  return news.map(singleNews => {
    const foundItem = closedItems.find(closedItem => { return closedItem.id === singleNews.id })

    if (foundItem === undefined) {
      return {
        news: singleNews,
        visible: true
      }
    }

    const twelveHoursAfterClosed = DateTimeUtils.addHours(foundItem.timeClosed, 12)

    return {
      news: singleNews,
      visible:
        // Item is visible if it has been modified after the user has closed it
        DateTimeUtils.is1After2(singleNews.lastModified, foundItem.timeClosed)
        // or 12 hours have passed
        || DateTimeUtils.isPastDate(twelveHoursAfterClosed)
    }
  })
}

const hideInLocalStorage = item => {
  const storageItems = window.localStorage.getItem(STORAGE_KEY)
  const closedItems = storageItems === null ? [] : JSON.parse(storageItems)

  const foundItem = closedItems.find(closedItem => { return closedItem.id === item.news.id })

  if (foundItem === undefined) {
    closedItems.push({
      id: item.news.id,
      timeClosed: new Date()
    })
  } else {
    foundItem.timeClosed = new Date()
  }

  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(closedItems))
}

export const BannerOperationalNewsModule = {
  namespaced: true,
  state: {
    items: []
  },
  mutations: {
    setItems (state, items) {
      state.items = items
    },
    setHidden (state, closedItem) {
      state.items.find(item => item.news.id === closedItem.news.id).visible = false
    }
  },
  getters: {
    getItems (state) {
      return JSON.parse(JSON.stringify(state.items))
    }
  },
  actions: {
    fetch ({ commit }) {
      return OperationalNewsService.getBannerNews(isLoggedIn())
        .then(news => {
          if (news.length > 0) {
            commit('setItems', wrapNews(news))
          }
        })
    },
    hide ({ commit }, closedItem) {
      hideInLocalStorage(closedItem)

      commit('setHidden', closedItem)
    }
  }
}
