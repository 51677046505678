<template>
  <CommonPageView
    class="tws-news-view"
    content-wrapper-class="content mt-5"
    :is-grey-background="false"
    :breadcrumb-items="breadcrumbItems"
    :loading="loading"
    :banner="banner">
    <template #content>
      <div class="container">
        <div class="row">
          <div class="col-md-7">
            <tws-loading-information
              :loading="loading"
              :exception="exception"
              :loading-text="$t('news.loading')"
              :exception-text="$t('news.loading-failed')" />
            <template v-if="loaded">
              <tws-news-view :item="news" />
              <button
                type="button"
                class="button button--secondary"
                @click="goBack">
                {{ $t('news.back') }}
              </button>
            </template>
          </div>
          <div class="col-md-4 offset-md-1">
            <tws-news-section-container
              v-if="loaded"
              class="news-list"
              :news-list-link="newsLink"
              :news-view-link="newsPagePath" />
          </div>
        </div>
      </div>
    </template>
  </CommonPageView>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex'

import { TwsLoadingInformation } from 'tws-vue-components'

import CommonPageView from '@/views/CommonPageView'

import TwsNewsView from '@/components/news-page/news-view/news-view'
import TwsNewsSectionContainer from '@/components/news-section/news-section-container'

import { convertDateTime } from '@/utils/filters.utils'
import i18n from '@/i18n'
import { isLoggedIn } from '@/utils/user.utils'

export default {
  components: {
    'tws-news-view': TwsNewsView,
    'tws-news-section-container': TwsNewsSectionContainer,
    TwsLoadingInformation,
    CommonPageView
  },
  data () {
    return {
      newsPagePath: '/news-archive/news-page',
      newsLink: '/news-archive'
    }
  },
  computed: {
    ...mapState('NewsDetails', [
      'loading',
      'loaded',
      'exception',
      'news'
    ]),
    banner () {
      return {
        heading: this.news.title,
        description: i18n.t('news.banner.published-at') + ' : ' + convertDateTime(this.news.publishDate)
      }
    },
    breadcrumbItems () {
      return this.news?.breadcrumbItems
    }
  },
  watch: {
    '$route.query' (newQuery) {
      this.loadNews(newQuery['news-id'])
    }
  },
  async created () {
    await this.loadNews()

    document.title = this.news.title
  },
  methods: {
    ...mapActions('NewsDetails', [
      'loadPublicNews',
      'loadPrivateNews'
    ]),
    goBack () {
      this.$router.go(-1)
    },
    loadNews (newsId = this.$route.query['news-id']) {
      return isLoggedIn() ? this.loadPrivateNews(newsId) : this.loadPublicNews(newsId)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.news-list {
  @media (max-width: $breakpoint-md) {
    margin-top: 50px;
  }
}
</style>
