<template>
  <TwsInformationDialog
    v-if="showDialog"
    :show-close-button="true"
    :header="$t('session-expiration.heading')"
    @close="handleDialog(false)">
    <template #body>
      <p>{{ $t('session-expiration.message') }}</p>
      <tws-button-primary
        class="continue-button"
        @click="handleDialog()">
        {{ $t('session-expiration.login') }}
      </tws-button-primary>
    </template>.
  </TwsInformationDialog>
</template>

<script>
import { TwsButtonPrimary } from 'tws-vue-components'
import AuthService from '@/services/auth.service'
import { EVENT_KEY_SESSION_TIMEOUT } from '@/common/auth-constants'
import TwsInformationDialog from '@/components/common/information-dialog'

export default {
  components: {
    TwsButtonPrimary,
    TwsInformationDialog
  },
  data () {
    return {
      showDialog: false
    }
  },
  created () {
    window.addEventListener(EVENT_KEY_SESSION_TIMEOUT, this.openDialog)
  },
  destroyed () {
    window.removeEventListener(EVENT_KEY_SESSION_TIMEOUT, this.openDialog)
  },
  methods: {
    openDialog () {
      this.showDialog = true
    },
    async handleDialog (login = true) {
      this.showDialog = false

      if (login) {
        await AuthService.signinRedirect()
        window.location.reload()
      } else {
        await this.$router.push({
          name: 'landing-page',
          params: {
            locale: this.$route.params.locale
          }
        })

        window.location.reload()
      }
    }
  }
}
</script>
