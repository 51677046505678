<template>
  <CommonPageView
    class="tws-news-view"
    :is-grey-background="false"
    :breadcrumb-items="breadcrumbItems"
    :loading="loading"
    :banner="banner">
    <template #content>
      <div class="row date-fields-container">
        <div class="col-md-3 col-sm-12">
          <tws-date-picker
            v-model="dateFrom"
            :label="$t('news.archive.date-from')"
            @input="loadNews" />
        </div>
        <div class="col-md-3 col-sm-12">
          <tws-date-picker
            v-model="dateTo"
            :label="$t('news.archive.date-to')"
            @input="loadNews" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-9 col-sm-12">
          <tws-loading-information
            class="loading-information"
            :loading="loading"
            :exception="exception"
            :exception-text="$t('news.archive.loading-failed')" />
          <tws-dashboard-informational-news
            :news="news"
            :link="'/news-page'"
            :title="$t('news.latest-news')" />
          <tws-pagination
            :current-page="currentPage"
            :extra-page-count="extraPageCount"
            :data-count="totalCount"
            :item-count-on-page="itemCountOnPage"
            class="pagination"
            @onPageClick="onPageClick" />
        </div>
      </div>
    </template>
  </CommonPageView>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex'

import CommonPageView from '@/views/CommonPageView'

import InformationalNews from '@/views/login-landing/dashboard/dashboard-informational-news'

import {
  TwsDatePicker,
  TwsPagination,
  TwsLoadingInformation
} from 'tws-vue-components'
import i18n from '@/i18n'
import { BREADCRUMBS_NEWS_ARCHIVE } from '@/constants/breadcrumbs-constants'
import { isLoggedIn } from '@/utils/user.utils'

export default {
  components: {
    CommonPageView,
    'tws-dashboard-informational-news': InformationalNews,
    TwsDatePicker,
    TwsPagination,
    TwsLoadingInformation
  },
  data () {
    return {
      dateFrom: null,
      dateTo: null,
      currentPage: 1,
      extraPageCount: 3,
      itemCountOnPage: 10,
      breadcrumbItems: BREADCRUMBS_NEWS_ARCHIVE
    }
  },
  computed: {
    ...mapState('news', [
      'loading',
      'exception',
      'news',
      'totalCount'
    ]),
    banner () {
      return {
        heading: i18n.t('dashboard-container.operational-news.action-button.news-archive')
      }
    }
  },
  mounted () {
    this.loadNews()
  },
  methods: {
    ...mapActions('news', [
      'loadPublicNews',
      'loadAllNews'
    ]),
    loadNews () {
      const form = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        page: this.currentPage,
        size: this.itemCountOnPage
      }

      isLoggedIn() ? this.loadAllNews(form) : this.loadPublicNews(form)
    },
    onPageClick (page) {
      this.currentPage = page
      this.loadNews()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.pagination {
  margin-top: 35px
}

.loading-information {
  padding: 20px 0px;
}
</style>
