<template>
  <CommonPageView
    class="tws-news-view"
    :breadcrumb-items="breadcrumbItems"
    :loading="loading"
    :banner="banner">
    <template #content>
      <div
        class="row">
        <div
          class="col">
          <h1
            class="heading-m other-news-heading">
            {{ $t('market-consultations.title') }}
          </h1>
          <div
            class="rich-text-container other-news-lead-paragraph">
            <p>
              <strong>
                {{ $t('market-consultations.other-news-lead-paragraph') }}
              </strong>
            </p>

            <p>
              <strong>
                {{ $t('market-consultations.list-title') }}
              </strong>
            </p>

            <ul>
              <li
                v-for="itemText in $t('market-consultations.list-items')"
                :key="itemText">
                {{ itemText }}
              </li>
            </ul>

            <p>
              {{ $t('market-consultations.closing-paragraph') }} <tws-nav-link
                :href="{ name: 'network-information'}"
                router-link
                class="search-result-item__header">
                {{ $t('market-consultations.closing-paragraph-link') }}.
              </tws-nav-link>
            </p>
          </div>
        </div>
      </div>

      <div class="row date-fields-container">
        <div class="col-md-3 col-sm-12">
          <tws-date-picker
            v-model="dateFrom"
            :label="$t('news.archive.date-from')"
            @input="loadNews" />
        </div>
        <div class="col-md-3 col-sm-12">
          <tws-date-picker
            v-model="dateTo"
            :label="$t('news.archive.date-to')"
            @input="loadNews" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-9 col-sm-12">
          <tws-loading-information
            class="loading-information"
            :loading="loading"
            :exception="exception"
            :exception-text="$t('news.archive.loading-failed')" />
          <tws-dashboard-informational-news
            :news="news"
            :link="'/news-page'" />
          <tws-pagination
            :current-page="currentPage"
            :data-count="totalCount"
            :extra-page-count="0"
            :item-count-on-page="itemCountOnPage"
            class="pagination"
            @onPageClick="onPageClick" />
        </div>
      </div>
    </template>
  </CommonPageView>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex'

import CommonPageView from '@/views/CommonPageView'

import InformationalNews from '@/views/login-landing/dashboard/dashboard-informational-news'

import {
  TwsDatePicker,
  TwsPagination,
  TwsLoadingInformation,
  TwsNavLink
} from 'tws-vue-components'
import i18n from '@/i18n'
import { BREADCRUMBS_MARKET_CONSULTATIONS } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    CommonPageView,
    'tws-dashboard-informational-news': InformationalNews,
    TwsDatePicker,
    TwsPagination,
    TwsLoadingInformation,
    TwsNavLink
  },
  data () {
    return {
      dateFrom: null,
      dateTo: null,
      currentPage: 1,
      itemCountOnPage: 10,
      breadcrumbItems: BREADCRUMBS_MARKET_CONSULTATIONS
    }
  },
  computed: {
    ...mapState('news', [
      'loading',
      'exception',
      'news',
      'totalCount'
    ]),
    banner () {
      return {
        heading: i18n.t('market-consultations.title')
      }
    }
  },
  mounted () {
    this.loadNews()
  },
  methods: {
    ...mapActions('news', [
      'loadPublicNews',
      'loadAllNews'
    ]),
    loadNews () {
      const form = {
        newsType: 'CONSULTATION',
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        page: this.currentPage,
        size: this.itemCountOnPage
      }

      this.loadAllNews(form)
    },
    onPageClick (page) {
      this.currentPage = page
      this.loadNews()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.other-news-heading {
  font-family: $teliaSans;
  font-weight: bold;
  margin: 40px 0 35px;
}

.other-news-lead-paragraph {
  margin-bottom: 35px;
}
</style>
