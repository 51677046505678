import { render, staticRenderFns } from "./news-section-container.vue?vue&type=template&id=862c6738&scoped=true"
import script from "./news-section-container.vue?vue&type=script&lang=js"
export * from "./news-section-container.vue?vue&type=script&lang=js"
import style0 from "./news-section-container.vue?vue&type=style&index=0&id=862c6738&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "862c6738",
  null
  
)

export default component.exports