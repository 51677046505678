<template>
  <div
    ref="mobileMenuContainer"
    class="mobile-menu-container">
    <div
      ref="mobileMenu"
      class="mobile-menu">
      <tws-mobile-menu-heading @close="$emit('close')" />

      <tws-mobile-menu-secured v-if="isLoggedIn()" />

      <div class="menu-items">
        <tws-mobile-menu-item
          v-for="(item, index) in itemsProxy"
          :key="index"
          :item="item">
          <div
            v-show="item.expanded"
            v-if="item.expandable"
            class="sub-menu-container">
            <tws-mobile-sub-menu-item
              v-for="(item2, index2) in megaMenusProxy[item.menu]"
              :key="index2"
              :item="item2">
              <div
                v-show="item2.expanded"
                class="sub-menu-container">
                <tws-mobile-sub-menu-item-2
                  v-for="(item3, index3) in item2.categories"
                  :key="index3"
                  :item="item3" />
              </div>
            </tws-mobile-sub-menu-item>
          </div>
        </tws-mobile-menu-item>
      </div>

      <div
        class="login-menu"
        :class="{'login-menu--not-signed-in': !isLoggedIn()}">
        <template v-if="isLoggedIn()">
          <tws-login-menu-user-info />

          <tws-borderless-button
            class="select-item"
            @click="onUserDashboardClick">
            {{ $t('header.settings-button') }}
          </tws-borderless-button>

          <tws-borderless-button
            class="select-item"
            @click="onLogoutClick">
            {{ $t('header.logout-button') }}
          </tws-borderless-button>
        </template>
        <tws-login-menu-login-button v-else />
      </div>
    </div>
  </div>
</template>

<script>
import MobileMenuSecured from './mobile-menu-secured'
import Heading from './mobile-menu-heading/mobile-menu-heading'
import Item from './mobile-menu-item/mobile-menu-item'
import SubItem from './mobile-sub-menu-item/mobile-sub-menu-item'
import SubItem2 from './mobile-sub-menu-item-2/mobile-sub-menu-item-2'
import LoginMenuUserInfo from '../login-menu/login-menu-user-info/login-menu-user-info'
import LoginMenuMixin from '@/mixins/login-menu.mixin'
import LoginMenuLoginButton from '@/components/header/login-menu/login-menu-login-button/login-menu-login-button'
import { TwsBorderlessButton } from 'tws-vue-components'
import { isLoggedIn } from '@/utils/user.utils'

export default {
  components: {
    'tws-mobile-menu-secured': MobileMenuSecured,
    'tws-mobile-menu-heading': Heading,
    'tws-mobile-menu-item': Item,
    'tws-mobile-sub-menu-item': SubItem,
    'tws-mobile-sub-menu-item-2': SubItem2,
    'tws-login-menu-user-info': LoginMenuUserInfo,
    'tws-login-menu-login-button': LoginMenuLoginButton,
    TwsBorderlessButton
  },
  mixins: [LoginMenuMixin],
  props: {
    items: {
      type: Array,
      required: true
    },
    megaMenus: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      itemsProxy: [],
      megaMenusProxy: {}
    }
  },
  created () {
    this.createItemsProxy()
    this.createMegaMenusProxy()

    document.addEventListener('click', this.handleOutsideClick)
  },
  destroyed () {
    document.removeEventListener('click', this.handleOutsideClick)
  },
  methods: {
    isLoggedIn,
    createItemsProxy () {
      const items = JSON.parse(JSON.stringify(this.items))

      items.forEach(item => {
        item['expandable'] = !item.link

        if (item.expandable) {
          item['expanded'] = false
        }
      })

      this.itemsProxy = items
    },
    createMegaMenusProxy () {
      const megaMenus = {}

      Object.keys(this.megaMenus).forEach(key => {
        if (this.megaMenus[key]) {
          megaMenus[key] = JSON.parse(JSON.stringify(this.megaMenus[key].categories))

          megaMenus[key].forEach(category => {
            category['expandable'] = category.categories && category.categories.length > 0

            if (category.expandable) {
              category['expanded'] = false
            }
          })
        }
      })

      this.megaMenusProxy = megaMenus
    },
    handleOutsideClick (event) {
      const mobileMenu = this.$refs.mobileMenu
      const mobileMenuContainer = this.$refs.mobileMenuContainer

      const target = event.target

      // selection should be hidden only when clicking outside the component
      if (target === mobileMenuContainer && mobileMenu !== target && !mobileMenu.contains(target)) {
        this.$emit('close')
      }

      if (mobileMenu.contains(target) && (target.nodeName === 'A' || target.nodeName === 'BUTTON')) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.mobile-menu-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.4)
}

.mobile-menu {
  float: right;
  height: 100%;
  width: 290px;
  background-color: $white;
  overflow-x: auto;
}

.login-menu {
  margin: 30px;

  &--not-signed-in {
    text-align: center;
  }
}

.mobile-menu-container::v-deep .tws-borderless-button {
  margin: 30px 0 0 30px;
  display: block;
  text-align: center;
  color: $core-purple;
}
</style>
