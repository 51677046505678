import {
  setTokenData, signOut
} from '@/common/auth-utils'
import { isLoggedIn } from '@/utils/user.utils'
import { EVENT_KEY_SESSION_TIMEOUT } from '@/common/auth-constants'
import { createInstance } from '@/services/axios.api'

export const api = createInstance(process.env.VUE_APP_USER_ADMIN_SERVICE_PIAM)

export async function refreshToken (refreshTokenValue) {
  const config = {
    params: {
      refresh_token: refreshTokenValue,
      timestamp: new Date().getTime()
    }
  }

  try {
    const response = await api.get('/external/token/refresh', config)
    await setTokenData(response)
  } catch (error) {
    await signOut()
    window.dispatchEvent(new Event(EVENT_KEY_SESSION_TIMEOUT))
  }
}

export async function checkAndRefreshToken () {
  try {
    const { authenticated } = await api.get('/external/token/check-refresh',
      {
        params: {
          timestamp: new Date().getTime()
        }
      }
    )
    const developmentEnv = process.env.NODE_ENV === 'development'
    const sessionExpired = isLoggedIn() && !authenticated && !developmentEnv
    if (sessionExpired) {
      // back-end and front-end state differs - show session timeout
      await signOut()
      window.dispatchEvent(new Event(EVENT_KEY_SESSION_TIMEOUT))
    }
  } catch (error) {
    await signOut()
  }
}
