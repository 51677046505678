export const LOCAL_KEY_ACCESS_TOKEN = 'access_token'
export const LOCAL_KEY_REFRESH_TOKEN = 'refresh_token'
export const LOCAL_KEY_EXPIRES_AT = 'expires_at'

export const COOKIE_KEY_ACCESS_TOKEN = 'access_token'
export const COOKIE_KEY_REFRESH_TOKEN = 'refresh_token'
export const COOKIE_KEY_EXPIRES_AT = 'expires_at'

export const COOKIE_KEY_OPTANON_ALERT_BOX_CLOSED = 'OptanonAlertBoxClosed'
export const COOKIE_KEY_OPTANON_CONSENT = 'OptanonConsent'

export const SESSION_KEY_CODE_VERIFIER = 'code_verifier'
export const SESSION_KEY_STATE = 'state'
export const SESSION_KEY_RETURN_URI = 'return_uri'

export const EVENT_KEY_SESSION_TIMEOUT = 'showSessionTimedOutDialog'

export const USER_FIELDS = {
  FIRST_NAME: 'fname',
  LAST_NAME: 'ename',
  COMPANY_NAME: 'company_name',
  COMPANY_CODE: 'company_code',
  USERNAME: 'username',
  ROLES: 'roles',
  EMAIL: 'email'
}
