<template>
  <div v-if="visibleTabs.length > 0">
    <mobile-breadcrumbs
      class="mobile"
      :tabs="visibleTabs"
      :active-tab-index="activeTabIndex" />
    <desktop-breadcrumbs
      class="desktop"
      :tabs="visibleTabs"
      :active-tab-index="activeTabIndex" />
  </div>
</template>

<script>
import MobileBreacrumbTabs from './breadcrumb-tabs-container-mobile'
import DesktopBreadcumbTabs from './breadcrumb-tabs-container-desktop'
import { isLoggedIn } from '@/utils/user.utils'

export default {
  components: {
    'mobile-breadcrumbs': MobileBreacrumbTabs,
    'desktop-breadcrumbs': DesktopBreadcumbTabs
  },
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    currentUri: {
      type: String,
      required: true
    }
  },
  computed: {
    visibleTabs () {
      if (isLoggedIn()) {
        return this.tabs
      }

      return this.tabs.filter(tab => tab.public !== false)
    },
    activeTabIndex () {
      return this.visibleTabs.findIndex(this.isTabActive)
    }
  },
  methods: {
    isTabActive (tab) {
      if (tab.name === 'root') {
        return this.currentUri === tab.link
      }

      return this.currentUri.includes(tab.link) || tab.link.includes(this.currentUri)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.desktop {
  @media (max-width: $breakpoint-md) {
    display: none;
  }
}

.mobile {
  @media (min-width: $breakpoint-md) {
    display: none;
  }
  @media (width: $breakpoint-md) {
    display: block;
  }
}
</style>
