import CategoriesView from '@/apps/contact-us/views/CategoriesView.vue'
import ItemsView from '@/apps/contact-us/views/ItemsView.vue'
import SubCategoriesView from '@/apps/contact-us/views/SubCategoriesView.vue'
import i18n from '@/i18n'

export default {
  path: 'kontakt',
  name: 'contact',
  component: CategoriesView,
  meta: { title: i18n.t('page-title.contact') },
  children: [{
    path: ':category',
    component: SubCategoriesView,
    children: [{
      path: ':subCategory',
      component: ItemsView
    }]
  }]
}
