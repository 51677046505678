import { getHost } from '@/utils/domain.utils'
import ContentfulWrapper from '@telia/contentful-wrapper'
import { isLoggedIn } from '@/utils/user.utils'
import { LOCAL_KEY_ACCESS_TOKEN } from '@/common/auth-constants'

function getDevToken () {
  if (process.env.NODE_ENV === 'development' && isLoggedIn()) {
    return localStorage.getItem(LOCAL_KEY_ACCESS_TOKEN)
  }
  return '_'
}

const contentfulGatewayPath = isLoggedIn()
  ? '/.api/infra/auth-gateway/contentful-gateway'
  : '/.api/infra/contentful-gateway'
const client = new ContentfulWrapper({
  space: process.env.VUE_APP_CONTENTFUL_SPACE,
  accessToken: getDevToken(),
  host: getHost(),
  assetsHost: getHost() + contentfulGatewayPath,
  environment: process.env.VUE_APP_CONTENTFUL_WRAPPER_ENVIRONMENT,
  basePath: contentfulGatewayPath + '/content'
})

export default {
  async fetchEntries (query) {
    try {
      return await client.getEntries(query)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching data from Contentful:', error)
      throw error
    }
  },
  async fetchEntry (id, query) {
    try {
      return await client.getEntry(id, query)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching data from Contentful:', error)
      throw error
    }
  }
}
