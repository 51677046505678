import TicketCreate from '@/apps/customer-tickets/views/TicketCreate'
import TicketDetails from '@/apps/customer-tickets/views/TicketDetails'
import TicketList from '@/apps/customer-tickets/views/TicketList'
import Tickets from '@/apps/customer-tickets/views/Tickets'
import i18n from '@/i18n'

const metaChildren = { meta: { title: i18n.t('page-title.tickets') } }

export default {
  path: 'tickets',
  name: 'tickets',
  component: Tickets,
  meta: {
    requiresAuth: true
  },
  redirect: {
    name: 'tickets-list'
  },
  children: [
    {
      name: 'tickets-list',
      path: 'list',
      component: TicketList,
      ...metaChildren
    },
    {
      name: 'tickets-create',
      path: 'create',
      component: TicketCreate,
      ...metaChildren
    },
    {
      name: 'tickets-details',
      path: 'details/:supportId',
      component: TicketDetails,
      ...metaChildren
    }
  ]
}
