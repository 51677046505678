import i18n from '@/i18n'
import {
  createInstance,
  createInstanceWithoutCredentials
} from '@/services/axios.api'
import { getNewsPayload } from '@/utils/news.utils'
export const apiLoggedIn = createInstance(process.env.VUE_APP_CONTENTFUL_GATEWAY_LOGGED_IN)
export const apiPublic = createInstanceWithoutCredentials(process.env.VUE_APP_CONTENTFUL_GATEWAY)

const config = {
  params: {
    locale: i18n.locale
  }
}

const OperationalNewsService = {
  getActualNews: async () => {
    return await apiLoggedIn.get('/operational-news/actual', config)
  },
  getPlannedNews: async () => {
    return await apiLoggedIn.get('/operational-news/planned', config)
  },
  getArchivedNews: async (form) => {
    return await apiLoggedIn.post('/operational-news/archived', getNewsPayload(form, config.params.locale))
  },
  getFrontpageNews: async () => {
    return await apiLoggedIn.get('/operational-news/frontpage', config)
  },
  getBannerNews: async (loggedIn) => {
    if (loggedIn) {
      return await apiLoggedIn.get('/operational-news/private/banner', config)
    }

    return await apiPublic.get('/operational-news/banner', config)
  }
}

export default OperationalNewsService
