import LoginView from '@/views/login/LoginView'
import AuthService from '@/services/auth.service'

export default [
  {
    path: 'login',
    name: 'login',
    component: LoginView,
    alias: [
      'request-reset-password',
      'request-reset-password/reset-password',
      'change-password'
    ],
    beforeEnter: async (to, _from, next) => {
      next()
    }
  },
  {
    path: 'login-redirect',
    name: 'landing-page',
    async beforeEnter () {
      await AuthService.signinRedirect()
    }
  }
]
