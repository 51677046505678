import { createInstance } from '@/services/axios.api'

import {
  buildAuthenticationUrl,
  storeReturnUri
} from '@/common/auth-utils'

export const api = createInstance(process.env.VUE_APP_USER_ADMIN_SERVICE_PIAM)

const AuthService = {
  signinRedirect: async () => {
    storeReturnUri()
    window.location = await buildAuthenticationUrl()
  },
  exchangeToken: async (code, codeVerifier) => {
    const config = {
      params: {
        code: code,
        code_verifier: codeVerifier
      }
    }

    return await api.get('/external/token/', config)
  },
  logout: async () => {
    await api.post('/external/token/logout')
  }
}

export default AuthService
